import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { IoNotifications } from "react-icons/io5";
import { TABLET_SIZE } from "../../../core/constants/utils";
import { useWindowSize } from "../../../core/hooks/useWindowSize";

const GlobalLoading = () => {
  const { width } = useWindowSize();

  return (
    <div
      className={
        "w-screen h-screen py-5 px-5 lg:py-10 lg:px-20 grid grid-cols-9 lg:grid-cols-12 gap-8"
      }
    >
      {width > TABLET_SIZE ? (
        <div className="col-span-3 h-full bg-white shadow-md rounded-xl py-10 px-8">
          <div className={"flex items-center gap-5"}>
            <SkeletonCircle size="16" />
            <SkeletonText
              w={100}
              noOfLines={2}
              spacing="4"
              skeletonHeight="2"
            />
          </div>

          <div className={"flex flex-col gap-5 mt-8"}>
            <Skeleton height="32px" />
            <Skeleton height="32px" />
            <Skeleton height="32px" />
            <Skeleton height="32px" />
          </div>

          <div className={"mt-20"}>
            <Skeleton
              startColor="blue.500"
              endColor="blue.400"
              height="54px"
              width={"80%"}
              marginX={"auto"}
            />
          </div>
        </div>
      ) : null}

      <div className={"col-span-9 flex flex-col gap-8"}>
        <div
          className={
            "w-full h-[96px] rounded-xl shadow-md bg-white flex items-center justify-between px-8 text-gray-500"
          }
        >
          <Skeleton height="32px" w={"144px"} />
          <IoNotifications size={24} />
        </div>
        <div
          className={
            "w-full h-full rounded-xl shadow-md bg-white flex items-center justify-between px-8 text-gray-500"
          }
        ></div>
      </div>
    </div>
  );
};

export default GlobalLoading;
