import { useMutation, useQuery } from "react-query";
import { filterFetch, normalFetch } from "../fetch-api/get";
import { postApi } from "../fetch-api/post";
import { useQueryContext } from "../../contexts/query";

export const notificationKeys = {
  notifications: "notifications",
  seeNotif: "seeNotif",
  notifications_unread: "notifications_unread",
};

export const useGetAllNotifications = (params) => {
  return useQuery({
    queryFn: () => filterFetch(params, `notifications`),
    queryKey: [notificationKeys.notifications, params],
    select: (res) => res?.data?.data,
  });
};

export const useMarkNotifAsSeen = () => {
  const { queryClient } = useQueryContext();

  return useMutation({
    mutationFn: (payload) => postApi(payload, "set_notifications_seen"),
    mutationKey: [notificationKeys.seeNotif],
    onSuccess: () => {
      queryClient.invalidateQueries([notificationKeys.notifications, notificationKeys.notifications_unread]);
    },
  });
};

export const useGetUnseenNotificationCount = () => {
  return useQuery({
    queryFn: () => normalFetch(`notifications_unread`),
    queryKey: [notificationKeys.notifications_unread],
    select: (res) => res?.data?.data,
  });
};
