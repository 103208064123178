import { Suspense, lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalLoading from "../../components/global/loading/GlobalLoading";
import { useSetAxiosHeaders } from "../../core/hooks/useSettings";

const Home = lazy(() => {
  return Promise.all([
    import("./Home"),
    new Promise((resolve) => setTimeout(resolve, 2000)),
  ]).then(([moduleExports]) => moduleExports);
});

const Index = () => {
  useSetAxiosHeaders();

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Suspense fallback={<GlobalLoading />}>
        <Home />
      </Suspense>
    </Router>
  );
};

export default Index;
