import { FaVideo, FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoIosChatboxes } from "react-icons/io";

export const validEmailRegex = /\S+@\S+\.\S+/

export const TABLET_SIZE = 1024
export const MOBILE_SIZE = 768


export const staticHours = [
    7, 8, 9, 10, 11, 12, 13, 14, 15,
    16, 17, 18, 19, 20
]

export const validContactTypes = {
    'video-call': 'online-video-call',
    'phone-call': 'phone',
    'chat-meet': 'chat',
    'visits': 'visit'
}

export const bookableTabs = [
    { name: 'video-call', Icon: FaVideo, id: 'online-video-call' },
    { name: 'phone-call', Icon: FaPhone, id: 'phone' },
    { name: 'visits', Icon: FaLocationDot, id: 'visit' },
    { name: 'chat-meet', Icon: IoIosChatboxes, id: 'chat' },
]
