import { useMutation, useQuery } from "react-query";
import { filterFetch, normalFetch } from "../fetch-api/get";
import { postApi, postFormData } from "../fetch-api/post";
import { useMainContext } from "../../contexts/main";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQueryContext } from "../../contexts/query";

export const authkeys = {
  profile: "profile",
  login: "login",
  logout: "logout",
  updateAvatar: "updateAvatar",
  uploadImage: "uploadImage",
  getWallet: "getWallet",
  updateProfile: "updateProfile",
  updateAboutMe: "updateAboutMe",
  income: "income",
};

export const useGetProfile = () => {
  const { setToken } = useMainContext();

  return useQuery({
    queryFn: () => normalFetch("profile_management"),
    queryKey: authkeys.profile,
    select: (res) => res?.data?.data,
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    retry: 0,
    onError: () => {
      setToken("");
    },
  });
};

export const useLogin = () => {
  const { setToken } = useMainContext();
  const toast = useToast();

  return useMutation({
    mutationFn: (data) => postApi(data, "login"),
    mutationKey: authkeys.login,
    onSuccess: (res) => {
      setToken(res?.data?.data?.access_token);
      toast({
        title: "login-success",
        description: res?.data?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });
};

export const useLogout = () => {
  const { setToken, setGlobalLoading } = useMainContext();
  const toast = useToast();

  return useMutation({
    mutationFn: () => postApi({}, "logout"),
    mutationKey: authkeys.logout,
    onSuccess: (res) => {
      setToken("");
      setGlobalLoading(false);
      toast({
        title: "logout-success",
        description: res?.data?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });
};

export const useUpdateAvatar = () => {
  const { t } = useTranslation();
  const { queryClient } = useQueryContext();
  const toast = useToast();

  return useMutation({
    mutationFn: (data) => postApi(data, "profile_management/update_avatar"),
    mutationKey: authkeys.updateAvatar,
    onSuccess: (res) => {
      queryClient.invalidateQueries([authkeys.profile]);
      toast({
        title: t("avatar-update-success"),
        description: res?.data?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });
};

export const useUploadImage = (onSuccess) => {
  return useMutation({
    mutationFn: (data) => postFormData(data, "packages/admin/images/upload"),
    mutationKey: authkeys.uploadImage,
    onSuccess,
  });
};

export const useGetWallets = (params) => {
  return useQuery({
    queryKey: authkeys.getWallet,
    queryFn: () => filterFetch(params, "wallets/all-wallets"),
    select: (res) => res?.data?.data,
  });
};

export const useUpdateProfile = () => {
  const { t } = useTranslation();
  const toast = useToast();

  return useMutation({
    mutationKey: authkeys.updateProfile,
    mutationFn: (data) =>
      postApi(data, "profile_management/update_contact_details"),
    onSuccess: (res) => {
      toast({
        title: t("update-success"),
        description: res?.data?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });
};

export const useUpdateAboutMe = () => {
  const { t } = useTranslation();
  const toast = useToast();

  return useMutation({
    mutationKey: authkeys.updateAboutMe,
    mutationFn: (data) =>
      postApi(data, "medical/doctors/doctors/update-about-me"),
    onSuccess: (res) => {
      toast({
        title: t("update-success"),
        description: res?.data?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });
};

export const useGetDoctorIncome = (doctor_id, dateRange) => {
  let params = {};
  if (dateRange?.length > 1) {
    const from = new Date(dateRange[0]);
    const to = new Date(dateRange[1]);
    params.from_date = `${from.getDate()}/${
      from.getMonth() + 1
    }/${from.getFullYear()}`;
    params.to_date = `${to.getDate()}/${to.getMonth() + 1}/${to.getFullYear()}`;
  }

  return useQuery({
    queryFn: () =>
      filterFetch(params, `medical/admin/get-doctor-income/${doctor_id}`),
    enabled: !!doctor_id,
    select: (res) => res?.data?.data,
    queryKey: [authkeys.income, dateRange],
  });
};
