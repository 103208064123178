import axios from "axios";
import { BASE_URL } from "../../constants/urls";


/**
 * Post api
 * @param {Object} data
 * @param {string} path
 * @param throwError
 * @returns {Promise}
 */
const postApi = (data, path, throwError = null) => {

    const resp = axios.post(
        BASE_URL + path, data
    )

    resp.catch(err => {
        if ((err?.response?.status >= 500 || !err?.response?.status) && throwError) {
            throwError(new Error(err))
        }
    })
    return resp
}

/**
 * Post api with token
 * @param {Object} data
 * @param {string} path
 * @returns {Promise}
 */
const postApiWithToken = (data, path) => {

    return axios.post(
        BASE_URL + path, data
    )
}

const postFormData = (data, path) => {

    const resp = axios.post(
        BASE_URL + path, data,
        {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }
    )

    return resp
}




export {
    postApi,
    postApiWithToken,
    postFormData
}

