import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { SOCKET_WS } from "../constants/urls";

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();

  useEffect(() => {
    const newSocket = io(SOCKET_WS,  {
      cors: {
        origin: SOCKET_WS,
        methods: ["GET"]
      }
    });
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
