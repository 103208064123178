import { createContext, useContext, useEffect, useState } from "react";
import { useSocket } from "./socket";
import { useGetProfile } from "../services/react-query/auth";
import { useQueryContext } from "./query";
import { useToast } from "@chakra-ui/react";
import { notificationKeys } from "../services/react-query/notifications";

const SocketContentContext = createContext();

export const useSocketContent = () => {
  return useContext(SocketContentContext);
};

export const SocketContentProvider = ({ children }) => {
  const socket = useSocket();
  const { data: profile } = useGetProfile();
  const toast = useToast()
  const { queryClient } = useQueryContext()

  /* user notifications */
  useEffect(() => {
    if (!!socket && !!profile) {
      socket.emit(`user-${profile.id}-notifications`);
      socket.on(`user-${profile.id}-notifications`, (data) => {
        toast({
          title: data?.content?.title,
          description: 
            <div dangerouslySetInnerHTML={{ __html: data?.content?.body }}>
            </div>
          ,
          status: "info",
          duration: 10000,
          isClosable: true,
        });
        queryClient.invalidateQueries([notificationKeys.notifications, notificationKeys.notifications_unread])
      });
    }

    return () => {
      if (!!socket && !!profile) {
        socket.off(`user-${profile?.id}-notifications`);
      }
    };
  }, [socket, profile]);

  /* global notifications */
  useEffect(() => {
    if (!!socket) {
      socket.emit(`user-global-notifications`);
      socket.on(`user-global-notifications`, (data) => {
        toast({
          title: data?.content?.title,
          description: 
            <div dangerouslySetInnerHTML={{ __html: data?.content?.body }}>
            </div>
          ,
          status: "info",
          duration: 10000,
          isClosable: true,
        });
        queryClient.invalidateQueries([notificationKeys.notifications, notificationKeys.notifications_unread])
      });
    }

    return () => {
      if (!!socket) {
        socket.off(`user-global-notifications`);
      }
    };
  }, [socket]);

  return (
    <SocketContentContext.Provider
    >
      {children}
    </SocketContentContext.Provider>
  );
};
